<div *ngIf="userData">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top custom-navbar">
    <div class="container-fluid">
      <!-- Left-aligned Logo -->
      <a class="" routerLink="/homepage">
        <img
          src="../../assets/images/futurenet_logo.png"
          alt="Logo"
          style="height: 34px"
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        (click)="toggleMenu()"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav d-lg-none me-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/homepage"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()"
            >
              {{ 'DASHBOARD' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngIf="hasRole(['applicant'])">
            <a
              class="nav-link"
              routerLink="/me/application"
              routerLinkActive="active"
              (click)="closeMenu()"
            >
              {{ 'MY_APPLICATION' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngIf="hasRole(['hr', 'hr-admin'])">
            <a
              class="nav-link"
              routerLink="/talent-pool"
              routerLinkActive="active"
              (click)="closeMenu()"
            >
              {{ 'TALENT_POOL' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngIf="hasRole(['hr', 'hr-admin'])">
            <a
              class="nav-link"
              routerLink="/company"
              routerLinkActive="active"
              (click)="closeMenu()"
            >
              {{ 'COMPANY' | translate }}
            </a>
          </li>
          <!-- Mobile Links -->
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/settings"
              routerLinkActive="active"
              (click)="closeMenu()"
            >
              {{ 'SETTINGS' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="logout(); closeMenu()">{{
              'LOGOUT' | translate
            }}</a>
          </li>
        </ul>

        <!-- Centered Links for Desktop -->
        <div class="d-none d-lg-flex mx-auto">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/homepage"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'DASHBOARD' | translate }}
              </a>
            </li>
            <li class="nav-item" *ngIf="hasRole(['applicant'])">
              <a class="nav-link" routerLink="/me/application" routerLinkActive="active">
                {{ 'MY_APPLICATION' | translate }}
              </a>
            </li>
            <li class="nav-item" *ngIf="hasRole(['hr', 'hr-admin'])">
              <a class="nav-link" routerLink="/talent-pool" routerLinkActive="active">
                {{ 'TALENT_POOL' | translate }}
              </a>
            </li>
            <li class="nav-item" *ngIf="hasRole(['hr', 'hr-admin'])">
              <a class="nav-link" routerLink="/company" routerLinkActive="active">
                {{ 'COMPANY' | translate }}
              </a>
            </li>
          </ul>
        </div>

        <!-- Right-aligned User Dropdown for Desktop -->
        <div class="d-none d-lg-flex justify-content-end gap-2">
          <ul class="navbar-nav">
            <li ngbDropdown class="nav-item dropdown align-content-center">
              <a
                ngbDropdownToggle
                class="nav-link dropdown-toggle p-0"
                id="navbarDropdown"
                role="button"
                aria-expanded="false"
              >
                <div class="user-initials-circle">
                  <span
                    *ngIf="
                      userData?.personalInfo?.givenName &&
                      userData?.personalInfo?.familyName
                    "
                  >
                    {{
                      userData.personalInfo.givenName[0].toUpperCase() +
                        userData.personalInfo.familyName[0].toUpperCase()
                    }}
                  </span>
                </div>
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
                ngbDropdownMenu
              >
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center gap-2"
                    routerLink="/settings"
                    routerLinkActive="active"
                  >
                    <i class="fa fa-cog"></i>
                    {{ 'SETTINGS' | translate }}
                  </a>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center gap-2"
                    routerLink="/"
                    (click)="logout()"
                  >
                    <i class="fa fa-sign-out-alt"></i>
                    {{ 'LOGOUT' | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <button
            *ngIf="currentLanguage === 'en'"
            class="btn btn-sm rounded-5 text-white"
            (click)="switchLanguage('de')"
          >
            DE
          </button>
          <button
            *ngIf="currentLanguage === 'de'"
            class="btn btn-sm rounded-5 text-white"
            (click)="switchLanguage('en')"
          >
            EN
          </button>
        </div>
      </div>
    </div>
  </nav>
  <div class="py-1 px-2">
    <router-outlet></router-outlet>
  </div>
</div>
