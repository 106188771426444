<!-- HR user homepage -->
<div *ngIf="hasRole(['hr', 'hr-admin'])" class="container-xxl custom-container mt-3">

  <!-- Welcome text -->
  <ng-container *ngIf="user?.personalInfo">
    <p class="text-primary fs-4 fw-bold text-start px-4 py-5">
      {{ 'WELCOME_WORD' | translate }}
      {{ user?.personalInfo?.givenName }}!
    </p>
  </ng-container>

  <!-- Box layout -->
  <div class="row g-4">

    <!-- [LEFT] Application box -->
    <div class="col-lg-6 col-md-12 col-sm-12 d-flex flex-column gap-5">
      <div class="shadow-sm bg-white rounded-4 mb-3" style="min-height: 300px; padding: 40px">
        <app-application></app-application>
      </div>
    </div>

    <!-- [RIGHT] Favorites box / Company box -->
    <div class="col-lg-6 col-md-12 col-sm-12 d-flex flex-column gap-2">

      <!-- Favorites box -->
      <div
        class="shadow-sm bg-white rounded-4 mb-3"
        style="min-height: 300px; padding: 40px"
      >
        <app-box-favourite
          [favourite]="favourite"
          [loaderFavourite]="loaderFavourite"
        ></app-box-favourite>
      </div>

      <!-- Company box -->
      <div
        class="shadow-sm bg-white rounded-4 mb-3"
        style="min-height: 300px; padding: 40px"
      >
        <app-box-company
          [company]="company"
        ></app-box-company>
      </div>

    </div>
  </div>
</div>

<!-- Applicant homepage -->
<div *ngIf="hasRole(['applicant'])" class="container-xxl custom-container mt-3">
  <div class="d-flex flex-column align-items-center justify-content-center gap-5">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <img
        src="assets/images/Onboarding-amico.svg"
        alt="Search People"
        style="width: 250px; height: auto"
      />
      <ng-container *ngIf="user?.personalInfo">
        <p class="text-secondary fs-5 text-center">
          {{ 'WELCOME_WORD' | translate }}
          <span class="text-primary fw-bold"
            >{{ user?.personalInfo?.givenName }}
            {{ user?.personalInfo?.familyName }}</span
          >, {{ 'WELCOME_TALENT' | translate }}
        </p>
      </ng-container>
    </div>
    <div class="card w-75">
      <div class="card-body text-center d-flex flex-column gap-3">
        <p class="fs-6 text-center">{{ 'ACCESS_APPLICATION' | translate }}</p>
        <div>
          <button
            (click)="goToCreateMyApplication()"
            class="btn btn-primary rounded-5 text-white"
          >
            {{ 'GO_TO_APPLICATION' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
