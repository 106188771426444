<div class="container-xxl">
  <button class="btn btn-link px-0 py-4" (click)="goBack()">
    <i class="fa fa-arrow-left"></i> {{ 'BACK' | translate }}
  </button>
  <h2>{{ 'UPDATE_MANAGER' | translate }}</h2>
  <form *ngIf="manager">
    <div class="mb-3">
      <label for="givenName" class="form-label">{{ 'GIVEN_NAME' | translate }}</label>
      <input
        type="text"
        id="givenName"
        [(ngModel)]="manager.attributes.givenName"
        name="givenName"
        class="form-control rounded-5"
      />
    </div>
    <div class="mb-3">
      <label for="familyName" class="form-label">{{ 'FAMILY_NAME' | translate }}</label>
      <input
        type="text"
        id="familyName"
        [(ngModel)]="manager.attributes.familyName"
        name="familyName"
        class="form-control rounded-5"
      />
    </div>
    <div class="mb-3">
      <label for="status" class="form-label">{{ 'STATUS' | translate }}</label>
      <div ngbDropdown class="dropdown">
        <button
          class="btn btn-sm rounded-5 btn-outline-secondary dropdown-toggle px-3"
          ngbDropdownToggle
        >
          {{ manager?.attributes?.status }}
        </button>
        <div ngbDropdownMenu>
          <button
            class="dropdown-item"
            *ngFor="let status of StatusEnum"
            (click)="selectStatus(status)"
          >
            {{ status }}
          </button>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="phoneNumber" class="form-label">{{
        'PHONE_NUMBER_EXE' | translate
      }}</label>
      <input
        type="text"
        id="phoneNumber"
        [(ngModel)]="manager.attributes.phoneNumber"
        name="phoneNumber"
        pattern="\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$"
        #phoneNumber="ngModel"
        class="form-control rounded-5"
      />
      <div
        *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)"
        class="text-danger"
      ></div>
    </div>

    <button
      type="button"
      class="btn btn-primary text-white btn-sm rounded-5 px-3"
      (click)="updateManager()"
    >
      {{ 'UPDATE' | translate }}
    </button>
  </form>
</div>
