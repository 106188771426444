<div *ngIf="!company" class="d-flex justify-content-center align-items-center h-100">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- Company box -->
<div *ngIf="company">

  <!-- Box header -->
  <div class="d-flex justify-content-between mb-4 align-items-center">

    <!-- Box title -->
    <span class="text-primary fs-6 fw-bold mb-2">{{ 'MY_COMPANY' | translate }}</span>

    <!-- Company logo -->
    <div *ngIf="company?.attributes?.logo?.data?.attributes?.url">
      <img
        [src]="getFullImageUrl(company.attributes.logo.data.attributes.url)"
        [title]="company.attributes.logo.data.attributes.name"
        class="p-2"
        style="max-height: 120px; max-width: 200px"
        loading="lazy"
      />
    </div>
  </div>

  <!-- Company name -->
  <div *ngIf="company?.attributes?.name">
    <div class="fs-4">
      {{ company.attributes.name }}
    </div>
  </div>

  <!-- Company managers -->
  <div *ngIf="company?.attributes?.manager?.data?.length ?? 0 > 0">
    <div class="fw-bold mt-3 mb-4 fs-6">{{ 'EMPLOYEES' | translate }}</div>
    <div class="d-flex flex-column gap-2">
      <div *ngFor="let manager of company.attributes.manager.data">
        <div class="d-flex align-items-center gap-2">
          <div class="user-initials-circle-light">
            {{
              manager.attributes.givenName[0].toUpperCase() +
                manager.attributes.familyName[0].toUpperCase()
            }}
          </div>
          {{ manager.attributes.givenName }} {{ manager.attributes.familyName }}
          <br />
          <a [href]="'mailto:' + manager.attributes.email">{{
            manager.attributes.email
          }}</a>
        </div>
      </div>
    </div>
  </div>

  <!-- See company button -->
  <div class="d-flex mt-3">
    <button
          class="btn btn-primary rounded-5 ms-auto text-white btn-md flex align-items-center px-3"
          [routerLink]="['/company']"
        >
          <div style="display: flex; align-items: center; font-size: 14px; gap: 5px">
            <span>{{ 'VIEW_COMPANY' | translate }}</span>
            <mat-icon>arrow_forward</mat-icon>
          </div>
        </button>
  </div>
</div>
