<div class="container-xxl">
  <button class="btn btn-link px-0 py-4" (click)="goBack()">
    <i class="fa fa-arrow-left"></i> {{ 'BACK' | translate }}
  </button>
  <h2>{{ 'UPDATE_COMPANY' | translate }}</h2>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'details'"
          (click)="setActiveTab('details')"
          >{{ 'DETAILS' | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'addresses'"
          (click)="setActiveTab('addresses')"
          >{{ 'ADDRESSES' | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'logo'"
          (click)="setActiveTab('logo')"
          >{{ 'LOGO' | translate }}</a
        >
      </li>
    </ul>
    <button
      type="button"
      class="btn btn-primary text-white btn-sm rounded-5 px-3"
      (click)="saveCompany()"
    >
      {{ 'UPDATE_COMPANY' | translate }}
    </button>
  </div>

  <div [ngSwitch]="activeTab">
    <div *ngSwitchCase="'details'">
      <form [formGroup]="companyForm" class="mb-5">
        <!-- Existing form fields -->
        <div class="mb-3">
          <label for="name" class="form-label">{{ 'COMPANY_NAME' | translate }}</label>
          <input
            type="text"
            id="name"
            formControlName="name"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="about" class="form-label">{{ 'ABOUT' | translate }}</label>
          <textarea
            id="about"
            formControlName="about"
            class="form-control rounded-4"
            rows="4"
          ></textarea>
        </div>
        <div class="mb-3">
          <label for="ceo" class="form-label">{{ 'CEO' | translate }}</label>
          <input
            type="text"
            id="ceo"
            formControlName="ceo"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="employees" class="form-label">{{
            'NUMBER_OF_EMPLOYEES' | translate
          }}</label>
          <input
            type="number"
            id="employees"
            formControlName="employees"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="website" class="form-label">{{ 'WEBSITE' | translate }}</label>
          <input
            type="text"
            id="website"
            formControlName="website"
            class="form-control rounded-5"
          />
        </div>
      </form>
    </div>

    <div *ngSwitchCase="'addresses'">
      <div class="mb-3">
        <button
          class="btn btn-outline-secondary mb-2 rounded-5 btn-sm"
          (click)="addAddress()"
        >
          {{ 'ADD_ADDRESS' | translate }}
        </button>
        <div
          *ngFor="let address of addresses.controls; let i = index"
          class="mb-3"
          [formGroup]="getAddressGroup(i)"
        >
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{ 'ADDRESS' | translate }} {{ i + 1 }}</h5>
              <div class="mb-2">
                <label for="streetNumber" class="form-label">{{
                  'STREET_NUMBER' | translate
                }}</label>
                <input
                  type="text"
                  formControlName="streetNumber"
                  class="form-control rounded-5"
                />
              </div>
              <div class="mb-2">
                <label for="street" class="form-label">{{ 'STREET' | translate }}</label>
                <input
                  type="text"
                  formControlName="street"
                  class="form-control rounded-5"
                />
              </div>
              <div class="mb-2">
                <label for="city" class="form-label">{{ 'CITY' | translate }}</label>
                <input
                  type="text"
                  formControlName="city"
                  class="form-control rounded-5"
                />
              </div>
              <div class="mb-2">
                <label for="postalCode" class="form-label">{{
                  'POSTAL_CODE' | translate
                }}</label>
                <input
                  type="text"
                  formControlName="postalCode"
                  class="form-control rounded-5"
                />
              </div>
              <div class="mb-2">
                <label for="country" class="form-label">{{
                  'COUNTRY' | translate
                }}</label>
                <input
                  type="text"
                  formControlName="country"
                  class="form-control rounded-5"
                />
              </div>
              <button class="btn btn-danger btn-sm rounded-5" (click)="removeAddress(i)">
                {{ 'REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'logo'">
      <form [formGroup]="companyForm" class="mb-5">
        <div class="mb-3">
          <label for="logo" class="form-label">{{ 'LOGO' | translate }}</label>
          <div *ngIf="company?.attributes?.logo?.data && !previewUrl">
            <img
              [src]="strapiBaseUrl + company?.attributes?.logo?.data?.attributes?.url"
              [alt]="company?.attributes?.logo?.data?.attributes?.name || 'Company Logo'"
              style="
                max-height: 150px;
                display: block;
                margin-bottom: 10px;
                max-width: 300px;
              "
            />
          </div>
          <div *ngIf="previewUrl">
            <img
              [src]="previewUrl"
              alt="New Company Logo"
              style="
                max-height: 150px;
                display: block;
                margin-bottom: 10px;
                max-width: 300px;
              "
            />
          </div>
          <input
            type="file"
            id="logo"
            (change)="onFileSelected($event)"
            class="form-control rounded-5"
          />
        </div>
      </form>
    </div>
  </div>
</div>
