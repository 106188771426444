<div
  *ngIf="loaderFavourite; else content"
  class="d-flex justify-content-center align-items-center h-100"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-template #content>
  <div *ngIf="favourite.length > 0; else noApplications">

    <!-- Favorites container -->
    <div class="d-grid overflow-hidden" style="gap: 20px">

      <!-- Box title -->
      <span class="text-primary fs-6 fw-bold mb-2">{{ 'MY_FAVOURITES' | translate }}</span>

      <!-- Favorites list -->
      <div *ngFor="let fav of favourite">
        <ng-container *ngIf="fav.attributes?.application?.data?.id">
          <div
            class="card-custom p-2 bg-light-discret rounded-4 d-flex align-items-center"
            (click)="navigateToTalentPool(fav.attributes.application.data.id)"
          >
            <div class="w-100 d-flex flex-column gap-2">
              <div class="d-flex align-items-center justify-content-between gap-2">
                <div class="d-flex align-items-center gap-2">
                  <img
                    [src]="
                      getFullImageUrl(
                        fav?.attributes?.application?.data?.attributes?.portrait?.data
                          ?.attributes?.formats?.thumbnail?.url
                      )
                    "
                    [title]="
                      fav?.attributes?.application?.data?.attributes?.portrait?.data
                        ?.attributes?.formats?.thumbnail?.url
                    "
                    class="rounded-circle img-thumbnail"
                    loading="lazy"
                    lcImgFallback
                  />
                  <div>
                    <div class="fw-bold">
                      {{
                        fav?.attributes?.application?.data?.attributes?.personalInfo?.data
                          ?.attributes?.givenName
                      }}
                      {{
                        fav?.attributes?.application?.data?.attributes?.personalInfo?.data
                          ?.attributes?.familyName
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <mat-icon class="favourite">favorite</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="d-flex mt-2">
        <button
          class="btn btn-primary rounded-5 ms-auto text-white btn-md flex align-items-center px-3"
          (click)="viewAllFavourites()"
        >
          <div style="display: flex; align-items: center; font-size: 14px; gap: 5px">
            <span>{{ 'VIEW_ALL_FAVOURITES' | translate }}</span>
            <mat-icon>arrow_forward</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>
  <ng-template #noApplications>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <img
        src="assets/images/Favourite.svg"
        alt="Search People"
        style="width: 250px; height: auto"
      />
      <p class="text-secondary text-center w-75">
        {{ 'NO_FAVOURITES_MESSAGE' | translate }}
      </p>
    </div>
  </ng-template>
</ng-template>
