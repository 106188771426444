<div class="selected-tags mt-2">
  <!-- Affichage des filtres multiples -->
  <ng-container *ngFor="let filterKey of multipleFilterKeys">
    <span
      *ngFor="let option of selectedMultipleFilters[filterKey]"
      class="bg-primary me-2 rounded-5 text-white px-2 py-1"
      style="font-size: 12px; cursor: pointer"
      (click)="removeSelection(filterKey, option)">
      {{ option.attributes.name }}
      <span
        style="margin-left: 8px"
        >&times;</span
      >
    </span>
  </ng-container>

  <!-- Affichage des filtres uniques -->
  <ng-container *ngFor="let filterKey of singleFilterKeys">
    <span
      *ngIf="selectedSingleFilters[filterKey]"
      class="bg-primary me-2 rounded-5 text-white px-2 py-1"
      style="font-size: 12px; cursor: pointer"
      (click)="removeSelection(filterKey, selectedSingleFilters[filterKey]!)">
      {{ selectedSingleFilters[filterKey]?.name }}
      <span
        style="margin-left: 8px"
        >&times;</span
      >
    </span>
  </ng-container>
</div>
