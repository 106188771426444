<!-- create-employee.component.html -->
<div class="container-xxl">
  <button class="btn btn-link px-0  py-4" (click)="goBack()">
    <i class="fa fa-arrow-left"></i> {{ 'BACK' | translate }}
  </button>
  <h2>{{ 'CREATE_EMPLOYEE' | translate }}</h2>
  <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="givenName" class="form-label"
        >{{ 'GIVEN_NAME' | translate }}<span class="text-danger">*</span></label
      >
      <input
        type="text"
        formControlName="givenName"
        class="form-control rounded-5"
        id="givenName"
        required
      />
      <div class="text-danger" *ngIf="employeeForm.get('givenName') as givenNameControl">
        <small *ngIf="givenNameControl.touched && givenNameControl.errors?.['required']">
          {{ 'GIVEN_NAME_REQUIRED' | translate }}
        </small>
      </div>
    </div>
    <div class="mb-3">
      <label for="familyName" class="form-label"
        >{{ 'FAMILY_NAME' | translate }}<span class="text-danger">*</span></label
      >
      <input
        type="text"
        formControlName="familyName"
        class="form-control rounded-5"
        id="familyName"
        required
      />
      <div
        class="text-danger"
        *ngIf="employeeForm.get('familyName') as familyNameControl"
      >
        <small
          *ngIf="familyNameControl.touched && familyNameControl.errors?.['required']"
        >
          {{ 'FAMILY_NAME_REQUIRED' | translate }}
        </small>
      </div>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label"
        >{{ 'EMAIL_ADDRESS' | translate }}<span class="text-danger">*</span></label
      >
      <input
        type="email"
        formControlName="email"
        class="form-control rounded-5"
        id="email"
        required
      />
      <div class="text-danger" *ngIf="employeeForm.get('email') as emailControl">
        <small *ngIf="emailControl.touched && emailControl.errors?.['required']">
          {{ 'EMAIL_REQUIRED' | translate }}
        </small>
        <small *ngIf="emailControl.touched && emailControl.errors?.['email']">
          {{ 'VALID_EMAIL' | translate }}
        </small>
      </div>
    </div>
    <div class="mb-3">
      <label for="role" class="form-label">Role<span class="text-danger">*</span></label>
      <select formControlName="role" class="form-control rounded-5" id="role" required>
        <option value="hr">HR</option>
        <option value="hr-admin">HR Admin</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="language" class="form-label"
        >{{ 'LANGUAGE' | translate }}<span class="text-danger">*</span></label
      >
      <select
        formControlName="language"
        class="form-control rounded-5"
        id="language"
        required
      >
        <option *ngFor="let language of languages" [value]="language.id">
          {{ language.attributes.name }}
        </option>
      </select>
      <div class="text-danger" *ngIf="employeeForm.get('language') as languageControl">
        <small *ngIf="languageControl.touched && languageControl.errors?.['required']">
          {{ 'LANGUAGE_REQUIRED' | translate }}
        </small>
      </div>
    </div>
    <div class="mb-3">
      <label for="phoneNumber" class="form-label">{{ 'PHONE_NUMBER' | translate }}</label>
      <input
        type="tel"
        formControlName="phoneNumber"
        class="form-control rounded-5"
        id="phoneNumber"
      />
    </div>
    <button
      type="submit"
      class="btn btn-primary my-3 rounded-5 text-white px-3 btn-sm"
      [disabled]="isLoading"
    >
      <span *ngIf="!isLoading">{{ 'CREATE' | translate }}</span>
      <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i></span>
    </button>
  </form>
</div>
