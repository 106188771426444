<div
  *ngIf="isLoading; else content"
  class="d-flex justify-content-center align-items-center"
  style="height: calc(100vh - 64px)"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!------------------------------- MODAL --------------------------------->

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-5" id="exampleModalLabel">
          {{ 'CREATE_PERFECT_MATCH' | translate }}
        </div>
        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="filterSearch?.attributes?.createdAt; else noFilterSearch">
          <!-- create card bootstrap -->
          <div class="card shadow-sm">
            <div
              class="card-header bg-light-subtle py-3 d-flex align-items-center justify-content-between"
            >
              <div class="card-title fs-5 m-0 p-0">
                {{ 'YOU_ALREADY_HAVE_FILTER' | translate }}
              </div>
              <button class="btn btn-outline-danger" (click)="removeFilterSearch()">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div class="card-body">
              <div>
                <p class="card-text d-flex gap-2 align-items-center">
                  <i class="fa-regular fa-calendar"></i>
                  <span>{{ 'FILTER_CREATION_DATE' | translate }}:</span>
                  <span class="fw-medium">
                    {{ formatDate(filterSearch!.attributes.createdAt) }}
                  </span>
                </p>
              </div>
              <div class="mt-3">
                <div class="card-title fs-6">
                  {{ 'YOUR_EDUCATION_FILTERS' | translate }}
                </div>
                <ng-container
                  *ngIf="
                    filterSearch?.attributes?.degrees?.data?.length ?? 0 > 0;
                    else filterNoDegree
                  "
                >
                  <div class="d-flex align-items-center flex-wrap gap-2 mb-3">
                    <div *ngFor="let degree of filterSearch?.attributes?.degrees?.data">
                      <div class="tag__degree">
                        {{ degree?.attributes?.name }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #filterNoDegree>
                  <p class="text-light-dark">{{ 'NO_EDUCATION_FILTERS' | translate }}</p>
                </ng-template>
              </div>
              <div class="mt-3">
                <div class="card-title fs-6">
                  {{ 'YOUR_SEARCH_TYPE_FILTERS' | translate }}
                </div>
                <ng-container
                  *ngIf="
                    filterSearch?.attributes?.employment_types?.data?.length ?? 0 > 0;
                    else filterNoEmploymentType
                  "
                >
                  <div class="mb-3">
                    <div
                      *ngFor="
                        let employment_type of filterSearch?.attributes?.employment_types
                          ?.data
                      "
                      class="mt-1"
                    >
                      <div class="d-flex gap-2 align-items-center">
                        <i class="fas fa-briefcase"></i>
                        {{ employment_type?.attributes?.name }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #filterNoEmploymentType>
                  <p class="text-light-dark">
                    {{ 'NO_SEARCH_TYPE_FILTERS' | translate }}
                  </p>
                </ng-template>
              </div>
              <div class="mt-3">
                <div class="card-title fs-6">
                  {{ 'YOUR_EXPERIENCE_FILTERS' | translate }}
                </div>
                <ng-container
                  *ngIf="
                    filterSearch?.attributes?.experience_ins?.data?.length ?? 0 > 0;
                    else filterNoExp
                  "
                >
                  <div class="d-flex align-items-center flex-wrap gap-2 mb-3">
                    <div
                      *ngFor="let exp of filterSearch?.attributes?.experience_ins?.data"
                    >
                      <div class="tag__experience">
                        {{ exp?.attributes?.name }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #filterNoExp>
                  <p class="text-light-dark">{{ 'NO_EXPERIENCE_FILTERS' | translate }}</p>
                </ng-template>
              </div>
              <div class="mt-3">
                <div class="card-title fs-6">
                  {{ 'YOUR_YEARS_OF_EXPERIENCE_FILTERS' | translate }}
                </div>
                <ng-container
                  *ngIf="
                    filterSearch?.attributes?.years?.length ?? 0 > 0;
                    else filterNoYears
                  "
                >
                  <div class="mb-3">
                    <div
                      *ngFor="let year of filterSearch?.attributes?.years"
                      class="mt-1"
                    >
                      <div class="d-flex gap-2 align-items-center">
                        <ng-container [ngSwitch]="year">
                          <p *ngSwitchCase="'Least0'">
                            {{ 'LEAST_0' | translate }}
                          </p>
                          <p *ngSwitchCase="'Least2'">
                            {{ 'LEAST_2' | translate }}
                          </p>
                          <p *ngSwitchCase="'Least5'">
                            {{ 'LEAST_5' | translate }}
                          </p>
                          <p *ngSwitchCase="'Least10'">
                            {{ 'LEAST_10' | translate }}
                          </p>
                          <p *ngSwitchDefault>
                            {{ 'NO_YEARS_OF_EXPERIENCE_SPECIFIED' | translate }}
                          </p>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="mt-3">
                  <div class="card-title fs-6">
                    {{ 'YOUR_LANGUAGE_FILTERS' | translate }}
                  </div>
                  <ng-container
                    *ngIf="
                      filterSearch?.attributes?.languages?.data?.length ?? 0 > 0;
                      else filterNoYears
                    "
                  >
                    <div
                      *ngFor="let language of filterSearch?.attributes?.languages?.data"
                      class="mt-1"
                    >
                      <div class="d-flex align-items-center gap-2">
                        <div class="d-flex gap-2 align-items-center w-25">
                          <i class="fas fa-globe"></i>
                          <p class="m-0">
                            {{ language?.attributes?.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #filterNoYears>
                    <p class="text-light-dark">
                      {{ 'NO_LANGUAGE_FILTERS' | translate }}
                    </p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="filterSearch?.attributes?.createdAt">
          <p class="mt-3 mb-2 text-info fs-6 lh-lg">
            <small>
              <span class="text-light-dark">{{
                'CREATE_NEW_SEARCH_FILTER' | translate
              }}</span>
              <mark>{{ 'OVERWRITE_SEARCH_FILTER' | translate }}</mark>
            </small>
          </p>
        </ng-container>

        <ng-template #noFilterSearch>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img
              src="assets/images/SearchFilter.svg"
              alt="Search People"
              style="width: 250px; height: auto"
            />
            <p class="text-secondary text-center w-75">
              {{ 'NO_SEARCH_FILTER' | translate }}
            </p>
          </div>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-secondary rounded-5 btn-sm"
          data-bs-dismiss="modal"
        >
          {{ 'CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary rounded-5 text-white btn-sm"
          (click)="createFilterSearch()"
        >
          {{ 'CREATE_NEW_PERFECT_MATCH' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--------------------------------- CONTENT ----------------------------------->

<ng-template #content>
  <div class="container-xxl d-flex flex-column mt-4">

    <!-- Search bar -->
    <div class="w-full d-flex align-items-center justify-content-start mt-2 search-bar-container">
      <span class="search-bar-icon"><mat-icon>search</mat-icon></span>
      <input
        type="text"
        class="form-control rounded-5 border border-2 border-primary text-light-dark search-bar-input"
        placeholder="{{ 'SEARCH' | translate }}"
        (input)="onSearchChange($event)"
        style="max-width: 500px; height: 32px; font-size: 13px"
      />
    </div>

    <!-- Dropdowns -->
    <div class="d-flex mt-2">
      <div class="d-flex gap-3 flex-wrap align-items-center">
        <app-dropdown-component
          *ngIf="employmentTypes"
          [dropdownKey]="'employmentType'"
          [dropdownName]="'EMPLOYMENT_TYPE' | translate"
          [options]="employmentTypes"
          (selectionChange)="handleOptionChange($event)"
        >
        </app-dropdown-component>
        <app-single-select-dropdown
          [dropdownKey]="'experienceLevel'"
          [dropdownName]="'EXPERIENCE_LEVEL' | translate"
          [options]="experienceOptions"
          (selectionChange)="handleSingleSelectChange($event)"
        ></app-single-select-dropdown>
        <app-dropdown-component
          *ngIf="experienceIns"
          [dropdownKey]="'experienceIn'"
          [dropdownName]="'EXPERIENCE_IN' | translate"
          [options]="experienceIns"
          (selectionChange)="handleOptionChange($event)"
        >
        </app-dropdown-component>
        <app-dropdown-component
          *ngIf="degrees"
          [dropdownKey]="'degreeProgram'"
          [dropdownName]="'DEGREE_PROGRAM' | translate"
          [options]="degrees"
          (selectionChange)="handleOptionChange($event)"
        >
        </app-dropdown-component>
        <app-dropdown-component
          *ngIf="languages"
          [dropdownKey]="'language'"
          [dropdownName]="'LANGUAGE' | translate"
          [options]="languages"
          (selectionChange)="handleOptionChange($event)"
        >
        </app-dropdown-component>
        <div
          class="refresh-icon btn btn-sm d-flex align-items-center justify-content-center"
          (click)="resetFilters()"
        >
          <mat-icon>refresh</mat-icon>
        </div>
      </div>
      <div class="right-container">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-primary btn-sm d-flex align-items-center gap-2 text-white rounded-5"
            style="white-space: nowrap"
            (click)="openModalPerfect()"
          >
            <i class="fa-solid fa-bell"></i>
            {{ 'PERFECT_MATCH' | translate }}
          </button>

          <div class="favourite-icon-search" (click)="toggleShowFavourites()">
            <mat-icon
              [ngClass]="{
                selected: showFavouritesOnly,
                unselected: !showFavouritesOnly
              }"
            >
              {{ showFavouritesOnly ? 'favorite' : 'favorite_border' }}
            </mat-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- Selected tags -->
    <div class="mb-3">
      <app-selected-tags
        [selectedMultipleFilters]="selectedMultipleFilters"
        [selectedSingleFilters]="selectedSingleFilters"
        (selectionRemoved)="handleSelectionRemoved($event)"
      ></app-selected-tags>
    </div>

    <div class="row flex-grow-1 overflow-auto">

      <!-- Applications list -->
      <div class="position-relative col-12 col-lg-5 h-100 d-flex flex-column">
        <div class="shadow-sm p-3 bg-white rounded flex-grow-1 overflow-auto">
          <div *ngIf="filteredApplications.length > 0; else noApplications">
            <div class="d-grid gap-2 overflow-hidden">
              <div
                *ngFor="let application of filteredApplications"
                class="position-relative"
              >
                <div
                  [ngClass]="{
                    'bg-primary-discret': selectedApplicationId === application.id,
                    'card-custom-style': selectedApplicationId !== application.id
                  }"
                  class="card-custom p-4 bg-light-discret rounded-4 d-flex align-items-center"
                  (click)="selectApplication(application.id)"
                >
                  <div
                    class="favourite-icon"
                    (click)="toggleFavourite(application.id); $event.stopPropagation()"
                  >
                    <mat-icon
                      [ngClass]="{
                        selected: (isFavourite(application.id) | async),
                        unselected: !(isFavourite(application.id) | async)
                      }"
                    >
                      {{
                        (isFavourite(application.id) | async)
                          ? 'favorite'
                          : 'favorite_border'
                      }}
                    </mat-icon>
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <div class="d-flex align-items-center gap-2">
                      <img
                        [src]="
                          getFullImageUrl(
                            application?.attributes?.portrait?.data?.attributes?.formats
                              ?.thumbnail?.url
                          )
                        "
                        [title]="
                          application?.attributes?.portrait?.data?.attributes?.formats
                            ?.thumbnail?.name
                        "
                        class="rounded-circle img-thumbnail"
                        loading="lazy"
                        lcImgFallback
                      />
                      <div class="details">
                        <div class="d-flex flex-column gap-1">
                          <div class="fw-bold text-truncate">
                            {{
                              application?.attributes?.personalInfo?.data?.attributes
                                ?.givenName
                            }}
                            {{
                              application?.attributes?.personalInfo?.data?.attributes
                                ?.familyName
                            }}
                          </div>
                          <div class="text-secondary text-truncate">
                            {{
                              application?.attributes?.personalInfo?.data?.attributes
                                ?.email
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="d-flex flex-wrap gap-2"
                        *ngIf="
                          application?.attributes?.workExperience?.experienceIns?.data
                        "
                      >
                        <div
                          class="tag__experience"
                          *ngFor="
                            let workExperience of application.attributes.workExperience
                              .experienceIns.data
                          "
                        >
                          {{ workExperience.attributes.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noApplications>
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="text-center">
                <img
                  src="assets/images/no-data-rafiki.svg"
                  alt="Search People"
                  style="width: 300px; height: auto"
                />
                <p class="text-secondary">
                  <small>{{ 'NO_APPLICANT_FOUND' | translate }}</small>
                </p>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- Desktop view for selected application -->
      <div class="col-12 col-lg-7 h-100" *ngIf="!isMobile">
        <div class="card h-100">
          <div *ngIf="selectedApplication">
            <div class="p-3 bg-white shadow-sm rounded-top">
              <div class="d-flex gap-3 align-items-center">
                <img
                  [src]="
                    getFullImageUrl(
                      selectedApplication.attributes.portrait?.data?.attributes?.formats
                        ?.small?.url
                    )
                  "
                  [title]="
                    selectedApplication.attributes.portrait?.data?.attributes?.formats
                      ?.small?.name
                  "
                  class="rounded-circle img-thumbnail img-tall"
                  loading="lazy"
                  lcImgFallback
                />
                <ng-container
                  *ngIf="selectedApplication?.attributes?.personalInfo?.data?.attributes"
                >
                  <div class="d-flex flex-column">
                    <h2 class="m-0 fs-4">
                      {{
                        selectedApplication.attributes.personalInfo.data.attributes
                          .givenName
                      }}
                      {{
                        selectedApplication.attributes.personalInfo.data.attributes
                          .familyName
                      }}
                    </h2>
                    <p class="m-0">
                      {{
                        selectedApplication.attributes.personalInfo.data.attributes.email
                      }}
                    </p>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    selectedApplication?.attributes?.personalInfo?.data?.attributes?.email
                  "
                >
                  <div class="ms-auto">
                    <a
                      [href]="
                        'mailto:' +
                        selectedApplication.attributes.personalInfo.data.attributes
                          .email +
                        '?subject=Antwort%20auf%20Ihre%20Bewerbung'
                      "
                      class="btn btn-primary btn-sm d-flex align-items-center gap-2 text-white rounded-5"
                      style="white-space: nowrap"
                    >
                      <i class="fas fa-sm fa-paper-plane"></i>
                      {{ 'SEND_MESSAGE' | translate }}
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="overflow-auto flex-grow-1" #contentContainer>
            <div *ngIf="selectedApplication">
              <div class="p-3 d-flex flex-column gap-4">
                <div>
                  <h4 class="text-primary fs-5">
                    {{ 'GENERAL_ACCOUNT_INFORMATION' | translate }}
                  </h4>
                  <div>
                    <p>
                      <ng-container
                        *ngIf="
                          selectedApplication?.attributes?.personalInfo?.data?.attributes
                            ?.user?.data?.attributes?.lastConnection;
                          else noLastConnection
                        "
                      >
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa-regular fa-calendar"></i>
                          <span>{{ 'LAST_CONNECTION' | translate }}: </span>
                          <span class="fw-medium">
                            {{
                              formatDate(
                                selectedApplication.attributes.personalInfo.data
                                  .attributes.user.data.attributes.lastConnection
                              )
                            }}
                          </span>
                        </div>
                      </ng-container>
                      <ng-template #noLastConnection>
                        <span class="text-light-dark">{{
                          'NEVER_LOGGED_IN' | translate
                        }}</span>
                      </ng-template>
                    </p>
                    <p>
                      <ng-container
                        *ngIf="
                          selectedApplication?.attributes?.personalInfo?.data?.attributes
                            ?.createdAt
                        "
                      >
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa-regular fa-calendar"></i>
                          <span>{{ 'ACCOUNT_CREATION_DATE' | translate }}: </span>
                          <span class="fw-medium">{{
                            formatDate(
                              selectedApplication.attributes.personalInfo.data.attributes
                                .createdAt
                            )
                          }}</span>
                        </div>
                      </ng-container>
                    </p>
                    <p>
                      <ng-container *ngIf="selectedApplication?.attributes?.beginDate">
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa-regular fa-calendar"></i>
                          <span>{{ 'AVAILABILITY_START_DATE' | translate }}: </span>
                          <span class="fw-medium">
                            {{ formatDate(selectedApplication.attributes.beginDate) }}
                          </span>
                        </div>
                      </ng-container>
                    </p>
                    <p>
                      <ng-container
                        *ngIf="selectedApplication?.attributes?.endDate; else noEndDate"
                      >
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa-regular fa-calendar"></i>
                          <span>{{ 'END_DATE_OF_AVAILABILITY' | translate }}: </span>
                          <span class="fw-medium">
                            {{ formatDate(selectedApplication.attributes.endDate) }}
                          </span>
                        </div>
                      </ng-container>
                      <ng-template #noEndDate>
                        <span class="text-light-dark">{{
                          'NO_END_DATE_SPECIFIED' | translate
                        }}</span>
                      </ng-template>
                    </p>
                    <div class="d-flex flex-wrap gap-2">
                      <ng-container
                        *ngIf="
                          selectedApplication?.attributes?.resume?.data?.attributes?.url
                        "
                        ><button
                          class="btn btn-outline-primary btn-sm rounded-5"
                          (click)="
                            openFile(
                              selectedApplication.attributes.resume.data.attributes.url
                            )
                          "
                        >
                          {{ 'VIEW_CURRICULUM_VITAE' | translate }}
                        </button></ng-container
                      >
                      <ng-container
                        *ngIf="selectedApplication?.attributes?.certificates?.data"
                      >
                        <div
                          *ngFor="
                            let testimony of selectedApplication?.attributes?.certificates
                              ?.data;
                            let i = index
                          "
                          class="d-flex gap-2"
                        >
                          <button
                            class="btn btn-outline-primary btn-sm rounded-5"
                            (click)="openFile(testimony.attributes.url)"
                          >
                            {{ 'TESTIMONY' | translate }}{{ i + 1 }}
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div>
                  <h4 class="text-primary fs-5">{{ 'LINGUISTIC_SKILLS' | translate }}</h4>
                  <ng-container *ngIf="selectedApplication?.attributes?.languages">
                    <div
                      *ngFor="let language of selectedApplication?.attributes?.languages"
                      class="mt-1"
                    >
                      <div class="d-flex align-items-center gap-2">
                        <div class="d-flex gap-2 align-items-center w-25">
                          <i class="fas fa-globe"></i>
                          <p class="m-0">
                            {{ language?.language?.data?.attributes?.name }}
                          </p>
                        </div>
                        <div class="d-flex gap-2 align-items-center">
                          <i class="fas fa-chart-line"></i>
                          <p class="m-0">
                            {{ language?.languageLevel?.data?.attributes?.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div>
                  <h4 class="text-primary fs-5">{{ 'SEARCHES' | translate }}</h4>
                  <ng-container
                    *ngIf="selectedApplication?.attributes?.lookingFors?.data"
                  >
                    <div
                      *ngFor="
                        let search of selectedApplication.attributes.lookingFors.data
                      "
                      class="mt-1"
                    >
                      <div class="d-flex gap-2 align-items-center">
                        <i class="fas fa-briefcase"></i>
                        {{ search?.attributes?.name }}
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div>
                  <h4 class="text-primary fs-5">{{ 'EXPERIENCES' | translate }}</h4>
                  <ng-container
                    *ngIf="selectedApplication?.attributes?.workExperience?.years"
                  >
                    <div class="fw-medium">
                      <ng-container
                        [ngSwitch]="selectedApplication.attributes.workExperience.years"
                      >
                        <p *ngSwitchCase="'Least0'">
                          {{ 'STARTING_FIELD' | translate }}
                        </p>
                        <p *ngSwitchCase="'Least2'">
                          {{ 'AT_LEAST_2_YEARS_EXPERIENCE' | translate }}
                        </p>
                        <p *ngSwitchCase="'Least5'">
                          {{ 'AT_LEAST_5_YEARS_EXPERIENCE' | translate }}
                        </p>
                        <p *ngSwitchCase="'Least10'">
                          {{ 'AT_LEAST_10_YEARS_EXPERIENCE' | translate }}
                        </p>
                        <p *ngSwitchDefault>
                          {{ 'YEARS_EXPERIENCE_NOT_SPECIFIED' | translate }}
                        </p>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      selectedApplication?.attributes?.workExperience?.otherExperience
                    "
                  >
                    <p>
                      <span class="fw-medium"
                        >{{ 'OTHER_EXPERIENCES' | translate }}:
                      </span>
                      {{ selectedApplication.attributes.workExperience.otherExperience }}
                    </p>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      selectedApplication?.attributes?.workExperience?.experienceIns?.data
                    "
                  >
                    <div class="d-flex align-items-center flex-wrap gap-2">
                      <div
                        *ngFor="
                          let experience of selectedApplication.attributes.workExperience
                            .experienceIns.data
                        "
                      >
                        <div class="tag__experience">
                          {{ experience?.attributes?.name }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div>
                  <h4 class="text-primary fs-5">{{ 'EDUCATION' | translate }}</h4>

                  <ng-container
                    *ngIf="
                      selectedApplication?.attributes?.degrees?.type as degreeType;
                      else noDegreeType
                    "
                  >
                    <div [ngSwitch]="degreeType" class="fw-medium">
                      <p *ngSwitchCase="'DEGREE'">{{ 'HAS_DEGREE' | translate }}</p>
                      <p *ngSwitchCase="'STUDYING'">
                        {{ 'CURRENTLY_STUDYING' | translate }}
                      </p>
                      <p *ngSwitchCase="'TRAINING_END'">
                        {{ 'COMPLETED_TRAINING' | translate }}
                      </p>
                      <p *ngSwitchCase="'TRAINING_PROGRESS'">
                        {{ 'IN_PROGRESS_TRAINING' | translate }}
                      </p>
                      <p *ngSwitchDefault>
                        {{ 'EDUCATIONAL_STATUS_UNSPECIFIED' | translate }}
                      </p>
                    </div>
                  </ng-container>

                  <ng-template #noDegreeType>
                    <p class="text-light-dark">
                      {{ 'NO_DEGREE_TYPE' | translate }}
                    </p>
                  </ng-template>

                  <ng-container
                    *ngIf="selectedApplication?.attributes?.degrees?.degrees?.data"
                  >
                    <div class="d-flex align-items-center flex-wrap gap-2 mb-3">
                      <div
                        *ngFor="
                          let degree of selectedApplication.attributes.degrees.degrees
                            .data
                        "
                      >
                        <div class="tag__degree">
                          {{ degree?.attributes?.name }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div
              *ngIf="!selectedApplication"
              class="d-flex justify-content-center align-items-center h-100"
            >
              <div class="text-center">
                <img
                  src="assets/images/searchPeople.svg"
                  alt="Search People"
                  style="width: 300px; height: auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile view for selected application -->
    <ng-container *ngIf="isMobile">
      <div
        *ngIf="selectedApplication"
        class="mobile-detail-view position-fixed bottom-0 w-100 bg-white shadow-lg p-3 mb-5 mt-3"
      >

        <div class="d-flex align-items-start justify-content-between">
          <button
            class="btn btn-outline-secondary"
            (click)="clearSelectedApplication()"
          >
            Back
          </button>

          <ng-container
              *ngIf="
                selectedApplication?.attributes?.personalInfo?.data?.attributes?.email
              "
            >
              <div>
                <a
                  [href]="
                    'mailto:' +
                    selectedApplication.attributes.personalInfo.data.attributes.email +
                    '?subject=Antwort%20auf%20Ihre%20Bewerbung'
                  "
                  class="btn btn-primary btn-sm d-flex align-items-center gap-2 text-white rounded-5 mt-1"
                  style="white-space: nowrap"
                >
                  <i class="fas fa-sm fa-paper-plane"></i> Send message
                </a>
              </div>
            </ng-container>
        </div>

        <div class="w-100">
          <div class="d-flex align-items-center gap-3">
            <img
              [src]="
                getFullImageUrl(
                  selectedApplication.attributes.portrait?.data?.attributes?.formats
                    ?.small?.url
                )
              "
              [title]="
                selectedApplication.attributes.portrait?.data?.attributes?.formats?.small
                  ?.name
              "
              class="rounded-circle img-thumbnail img-tall"
              loading="lazy"
              lcImgFallback
            />
            <div class="d-flex flex-column">
              <h2 class="m-0 fs-4">
                {{
                  selectedApplication.attributes.personalInfo.data.attributes.givenName
                }}
                {{
                  selectedApplication.attributes.personalInfo.data.attributes.familyName
                }}
              </h2>
              <p class="m-0">
                {{ selectedApplication.attributes.personalInfo.data.attributes.email }}
              </p>
            </div>
          </div>
          <div class="mt-3">
            <h4 class="text-primary fs-5">
              {{ 'GENERAL_ACCOUNT_INFORMATION' | translate }}
            </h4>
            <div
              *ngIf="
                selectedApplication?.attributes?.personalInfo?.data?.attributes?.user
                  ?.data?.attributes?.lastConnection;
                else noLastConnection
              "
            >
              <div class="d-flex align-items-center gap-2">
                <i class="fa-regular fa-calendar"></i>
                <span>{{ 'LAST_CONNECTION' | translate }}: </span>
                <span class="fw-medium">{{
                  formatDate(
                    selectedApplication.attributes.personalInfo.data.attributes.user.data
                      .attributes.lastConnection
                  )
                }}</span>
              </div>
            </div>
            <ng-template #noLastConnection>
              <span class="text-light-dark">{{ 'NEVER_LOGGED_IN' | translate }}</span>
            </ng-template>
            <div
              *ngIf="
                selectedApplication?.attributes?.personalInfo?.data?.attributes?.createdAt
              "
            >
              <div class="d-flex align-items-center gap-2">
                <i class="fa-regular fa-calendar"></i>
                <span>{{ 'ACCOUNT_CREATION_DATE' | translate }}: </span>
                <span class="fw-medium">{{
                  formatDate(
                    selectedApplication.attributes.personalInfo.data.attributes.createdAt
                  )
                }}</span>
              </div>
            </div>
            <div *ngIf="selectedApplication?.attributes?.beginDate">
              <div class="d-flex align-items-center gap-2">
                <i class="fa-regular fa-calendar"></i>
                <span>{{ 'AVAILABILITY_START_DATE' | translate }}: </span>
                <span class="fw-medium">{{
                  formatDate(selectedApplication.attributes.beginDate)
                }}</span>
              </div>
            </div>
            <div *ngIf="selectedApplication?.attributes?.endDate; else noEndDate">
              <div class="d-flex align-items-center gap-2">
                <i class="fa-regular fa-calendar"></i>
                <span>{{ 'END_DATE_OF_AVAILABILITY' | translate }}: </span>
                <span class="fw-medium">{{
                  formatDate(selectedApplication.attributes.endDate)
                }}</span>
              </div>
            </div>
            <ng-template #noEndDate>
              <span class="text-light-dark">{{
                'NO_END_DATE_SPECIFIED' | translate
              }}</span>
            </ng-template>
            <div class="d-flex flex-wrap gap-2 mt-3">
              <ng-container
                *ngIf="selectedApplication?.attributes?.resume?.data?.attributes?.url"
              >
                <button
                  class="btn btn-outline-secondary btn-sm rounded-5"
                  (click)="
                    openFile(selectedApplication.attributes.resume.data.attributes.url)
                  "
                >
                  {{ 'VIEW_CURRICULUM_VITAE' | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="selectedApplication?.attributes?.certificates?.data">
                <div
                  *ngFor="
                    let testimony of selectedApplication?.attributes?.certificates?.data;
                    let i = index
                  "
                  class="d-flex gap-2"
                >
                  <button
                    class="btn btn-outline-secondary btn-sm rounded-5"
                    (click)="openFile(testimony.attributes.url)"
                  >
                    {{ 'TESTIMONY' | translate }}{{ i + 1 }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="mt-3">
            <h4 class="text-primary fs-5">{{ 'LINGUISTIC_SKILLS' | translate }}</h4>
            <ng-container *ngIf="selectedApplication?.attributes?.languages">
              <div
                *ngFor="let language of selectedApplication?.attributes?.languages"
                class="mt-1"
              >
                <div class="d-flex align-items-center gap-2">
                  <div class="d-flex gap-2 align-items-center w-25">
                    <i class="fas fa-globe"></i>
                    <p class="m-0">{{ language?.language?.data?.attributes?.name }}</p>
                  </div>
                  <div class="d-flex gap-2 align-items-center">
                    <i class="fas fa-chart-line"></i>
                    <p class="m-0">
                      {{ language?.languageLevel?.data?.attributes?.name }}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="mt-3">
            <h4 class="text-primary fs-5">
              {{ 'YOUR_SEARCH_TYPE_FILTERS' | translate }}
            </h4>
            <ng-container *ngIf="selectedApplication?.attributes?.lookingFors?.data">
              <div
                *ngFor="let search of selectedApplication.attributes.lookingFors.data"
                class="mt-1"
              >
                <div class="d-flex gap-2 align-items-center">
                  <i class="fas fa-briefcase"></i>
                  {{ search?.attributes?.name }}
                </div>
              </div>
            </ng-container>
          </div>
          <div class="mt-3">
            <h4 class="text-primary fs-5">{{ 'YOUR_EXPERIENCE_FILTERS' | translate }}</h4>
            <ng-container *ngIf="selectedApplication?.attributes?.workExperience?.years">
              <div class="fw-medium">
                <ng-container
                  [ngSwitch]="selectedApplication.attributes.workExperience.years"
                >
                  <p *ngSwitchCase="'Least0'">
                    {{ 'STARTING_FIELD' | translate }}
                  </p>
                  <p *ngSwitchCase="'Least2'">
                    {{ 'AT_LEAST_2_YEARS_EXPERIENCE' | translate }}
                  </p>
                  <p *ngSwitchCase="'Least5'">
                    {{ 'AT_LEAST_5_YEARS_EXPERIENCE' | translate }}
                  </p>
                  <p *ngSwitchCase="'Least10'">
                    {{ 'AT_LEAST_10_YEARS_EXPERIENCE' | translate }}
                  </p>
                  <p *ngSwitchDefault>
                    {{ 'YEARS_EXPERIENCE_NOT_SPECIFIED' | translate }}
                  </p>
                </ng-container>
              </div>
            </ng-container>
            <ng-container
              *ngIf="selectedApplication?.attributes?.workExperience?.otherExperience"
            >
              <p>
                <span class="fw-medium">{{ 'OTHER_EXPERIENCES' | translate }}: </span>
                {{ selectedApplication.attributes.workExperience.otherExperience }}
              </p>
            </ng-container>
            <ng-container
              *ngIf="selectedApplication?.attributes?.workExperience?.experienceIns?.data"
            >
              <div class="d-flex align-items-center flex-wrap gap-2">
                <div
                  *ngFor="
                    let experience of selectedApplication.attributes.workExperience
                      .experienceIns.data
                  "
                >
                  <div class="tag__experience">
                    {{ experience?.attributes?.name }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="mt-3">
            <h4 class="text-primary fs-5">{{ 'YOUR_EDUCATION_FILTERS' | translate }}</h4>
            <ng-container
              *ngIf="
                selectedApplication?.attributes?.degrees?.type as degreeType;
                else noDegreeType
              "
            >
              <div [ngSwitch]="degreeType" class="fw-medium">
                <p *ngSwitchCase="'DEGREE'">{{ 'HAS_DEGREE' | translate }}</p>
                <p *ngSwitchCase="'STUDYING'">
                  {{ 'CURRENTLY_STUDYING' | translate }}
                </p>
                <p *ngSwitchCase="'TRAINING_END'">
                  {{ 'COMPLETED_TRAINING' | translate }}
                </p>
                <p *ngSwitchCase="'TRAINING_PROGRESS'">
                  {{ 'IN_PROGRESS_TRAINING' | translate }}
                </p>
                <p *ngSwitchDefault>
                  {{ 'EDUCATIONAL_STATUS_UNSPECIFIED' | translate }}
                </p>
              </div>
            </ng-container>
            <ng-template #noDegreeType>
              <p class="text-light-dark">
                {{ 'NO_DEGREE_TYPE' | translate }}
              </p>
            </ng-template>
            <ng-container *ngIf="selectedApplication?.attributes?.degrees?.degrees?.data">
              <div class="d-flex align-items-center flex-wrap gap-2 mb-3">
                <div
                  *ngFor="
                    let degree of selectedApplication.attributes.degrees.degrees.data
                  "
                >
                  <div class="tag__degree">
                    {{ degree?.attributes?.name }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
