<div class="d-flex align-items-center justify-content-center vh-100 position-relative">
  <div class="login-form d-flex flex-column flex-grow-1 gap-3">
    <div class="text-center mb-4">
      <img
        src="../../assets/images/futurenet_logo_text_black.png"
        alt="Logo"
        class="mb-3 w-75"
      />
    </div>
    <div class="text-center text-black-50">
      <h6 class="fs-6">{{ 'WELCOME' | translate }}</h6>
      <hr class="mb-4 mt-3" style="width: 80%; margin: auto" />
    </div>
    <div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <label for="email" class="form-label"
            >{{ 'EMAIL_ADDRESS' | translate }}<span class="text-danger">*</span></label
          >
          <input
            type="email"
            formControlName="email"
            class="form-control rounded-5 border-primary mail-input"
            autocomplete="email"
            id="email"
            required
          />
          <div class="text-danger" *ngIf="loginForm.get('email') as emailControl">
            <small *ngIf="emailControl.touched && emailControl.errors?.['required']">
              {{ 'EMAIL_REQUIRED' | translate }}
            </small>
            <small *ngIf="emailControl.touched && emailControl.errors?.['email']">
              {{ 'VALID_EMAIL' | translate }}
            </small>
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label" style="width: 100%">
            {{ 'PASSWORD' | translate }}<span class="text-danger">*</span>
          </label>
          <div class="password-container">
            <input
              type="{{ showPassword ? 'text' : 'password' }}"
              formControlName="password"
              class="form-control rounded-5 border-primary password-input"
              autocomplete="off"
              id="password"
              required
            />
            <span class="toggle-password"><mat-icon (click)="switchPasswordVisibility()">{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon></span>
          </div>
          <div class="text-danger" *ngIf="loginForm.get('password') as passwordControl">
            <small
              *ngIf="passwordControl.touched && passwordControl.errors?.['required']"
            >
              {{ 'PASSWORD_REQUIRED' | translate }}
            </small>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <a href="/recovery/forgot" class="text-primary" style="font-size: 0.9em">{{
              'FORGOT_PASSWORD' | translate
            }}</a>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-primary my-3 rounded-5 w-100 text-white"
          [disabled]="isLoading"
        >
          <span *ngIf="!isLoading">{{ 'LOGIN' | translate }}</span>
          <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i></span>
        </button>
      </form>
    </div>
    <div class="position-absolute bottom-0 left-0 mb-5">
      <div class="d-flex gap-2">
        <span
        *ngFor="let lang of getLanguages()"
        class="text-primary"
        style="font-size: 0.8em; cursor: pointer; text-decoration: underline;"
        (click)="changeLanguage(lang)">
          {{ lang?.toUpperCase() }}
        </span>
        <span class="text-primary">•</span>
        <a
          [href]="'https://futurenet.de/informationen/impressum/'"
          target="_blank"
          class="text-primary"
          style="font-size: 0.8em"
          >{{ 'IMPRINT' | translate }}</a
        >
        <a
          [href]="'https://futurenet.de/informationen/datenschutz/'"
          target="_blank"
          class="text-primary"
          style="font-size: 0.8em"
          >{{ 'PRIVACY_POLICY' | translate }}</a
        >
      </div>
    </div>
  </div>
  <div class="login-pattern"></div>
</div>
