<div
  *ngIf="!company"
  class="d-flex justify-content-center align-items-center"
  style="height: calc(100vh - 64px)"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-container *ngIf="company">
  <div class="container-xxl d-flex flex-column pt-3 mt-4">
    <div class="w-100 d-flex align-items-start justify-content-between">
      <ng-container *ngIf="company?.attributes?.name">
        <div>
          <div class="fs-3 text-primary">
            {{ company.attributes.name }}
          </div>
          <ng-container>
            <div class="fw-bold mt-3 mb-2 fs-6">
              {{ 'GENERAL_INFORMATION' | translate }}
            </div>
          </ng-container>
          <ng-container *ngIf="company?.attributes?.ceo">
            <div>
              {{ 'CEO' | translate }}:
              <span class="fw-medium">{{ company.attributes.ceo }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="company?.attributes?.createdAt">
            <div class="d-flex align-items-center gap-2">
              <div>{{ 'CREATED_ON_THE_PLATFORM' | translate }}</div>
              <div class="fw-medium">
                {{ formatDate(company.attributes.createdAt) }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="company?.attributes?.employees">
            <div>
              {{ 'NUMBER_OF_EMPLOYEES' | translate }}:
              <span class="fw-medium">{{ company.attributes.employees }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="company?.attributes?.website">
            <div class="fw-bold mt-3 mb-2 fs-6">{{ 'WEBSITE' | translate }}</div>
            <div>
              <a
                [href]="addHttpsPrefix(company.attributes.website)"
                target="_blank"
                class="d-flex align-items-center gap-2 no-underline"
              >
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                {{ company.attributes.website }}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="company?.attributes?.addresses?.length ?? 0 > 0">
            <div class="fw-bold mt-3 mb-2 fs-6">{{ 'ADDRESSES' | translate }}</div>
            <ng-container *ngFor="let adress of company.attributes.addresses">
              <div class="d-flex align-items-center gap-2">
                <i class="fa-regular fa-map"></i>
                {{ adress.streetNumber }}
                {{ adress.street }}
                {{ adress.postalCode }}
                {{ adress.city }}
                {{ adress.country }}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="d-flex align-items-end flex-column">
        <ng-container *ngIf="company?.attributes?.logo?.data?.attributes?.url">
          <img
            [src]="getFullImageUrl(company.attributes.logo.data.attributes.url)"
            [title]="company.attributes.logo.data.attributes.name"
            class="p-2"
            style="max-height: 120px; max-width: 300px"
            loading="lazy"
          />
        </ng-container>
        <div class="mt-3">
          <button
            class="btn btn-primary btn-sm text-white rounded-5 d-flex align-items-center gap-2"
            (click)="goToEditCompany()"
          >
            <i class="fa-solid fa-edit"></i>
            {{ 'EDIT_COMPANY' | translate }}
          </button>
        </div>
        <div class="mt-3">
          <button
            class="btn btn-primary btn-sm text-white rounded-5 d-flex align-items-center gap-2"
            (click)="goToCreateEmployee()"
          >
            <i class="fa-solid fa-plus"></i>
            {{ 'CREATE_EMPLOYEE' | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- Separator -->
    <div style="width: 100%; height: 1px; background: lightgray" class="mt-5"></div>

    <!-- Employees list -->
    <ng-container *ngIf="company?.attributes?.manager?.data?.length ?? 0 > 0">
      <div class="fw-bold mt-5 mb-4 fs-5">{{ 'ACCOUNTS' | translate }}</div>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">{{ 'INITIALS' | translate }}</th>
            <th scope="col">{{ 'NAME' | translate }}</th>
            <th scope="col">{{ 'EMAIL' | translate }}</th>
            <th scope="col">{{ 'ROLE' | translate }}</th>
            <th scope="col">{{ 'ACTIONS' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let manager of company?.attributes?.manager?.data">
            <tr>
              <td class="align-middle">
                <div class="user-initials-circle">
                  {{
                    manager.attributes.givenName[0].toUpperCase() +
                      manager.attributes.familyName[0].toUpperCase()
                  }}
                </div>
              </td>
              <td class="align-middle">
                {{ manager.attributes.givenName }} {{ manager.attributes.familyName }}
              </td>
              <td class="align-middle">
                <a [href]="'mailto:' + manager.attributes.email">{{
                  manager.attributes.email
                }}</a>
              </td>
              <td class="align-middle">
                {{
                  manager?.attributes?.user?.data?.attributes?.role?.data?.attributes
                    ?.name
                }}
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-outline-secondary btn-sm me-2 rounded-5"
                  (click)="editManager(manager)"
                >
                  {{ 'EDIT' | translate }}
                </button>
                <button
                  class="btn btn-danger btn-sm rounded-5"
                  (click)="openDeleteModal(manager, deleteModal)"
                >
                  {{ 'DELETE' | translate }}
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container>

<!-- Modal -->
<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <div class="modal-title fs-5">{{ 'CONFIRM_DELETION' | translate }}</div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss()"
    ></button>
  </div>
  <div class="modal-body">{{ 'DELETE_MANAGER_CONFIRMATION' | translate }}</div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary btn-sm rounded-5"
      (click)="modal.dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-danger btn-sm rounded-5"
      (click)="confirmDelete()"
    >
      {{ 'CONFIRM_DELETE' | translate }}
    </button>
  </div>
</ng-template>
