import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { API_CONFIG } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class FavouriteService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private showFavouritesSubject = new BehaviorSubject<boolean>(false);
  showFavourites$ = this.showFavouritesSubject.asObservable();

  setShowFavourites(show: boolean): void {
    this.showFavouritesSubject.next(show);
  }

  getFavourites(userId: number): Observable<any[]> {
    const url =
      `${API_CONFIG.endpoint}/favourites?populate[application][populate]=true&filters[user][id][$eq]=` +
      userId;
    return this.http.get<{ data: any[] }>(url).pipe(
      map((response) => response.data.filter((favourite) => favourite?.attributes?.application?.data)),
      catchError((error) => {
        this.toastr.error(this.translate.instant('FAVOURITES_LOADING_ERROR'));
        return throwError(() => new Error('Erreur lors du chargement des favoris'));
      })
    );
  }

  addFavourite(userId: number, applicationId: number): Observable<any> {
    const url = `${API_CONFIG.endpoint}/favourites`;
    return this.http
      .post(url, { data: { user: userId, application: applicationId } })
      .pipe(
        catchError((error) => {
          this.toastr.error(this.translate.instant('FAVOURITE_ADD_ERROR'));
          return throwError(() => new Error("Erreur lors de l'ajout du favori"));
        })
      );
  }

  removeFavourite(favouriteId: number): Observable<any> {
    const url = `${API_CONFIG.endpoint}/favourites/${favouriteId}`;
    return this.http.delete(url).pipe(
      catchError((error) => {
        this.toastr.error(this.translate.instant('FAVOURITE_REMOVE_ERROR'));
        return throwError(() => new Error('Erreur lors de la suppression du favori'));
      })
    );
  }

  getFavouriteList(userId: number): Observable<any[]> {
    const url = `${API_CONFIG.endpoint}/favourites?populate[application][populate][personalInfo]=true&populate[application][populate][portrait][populate]=true&filters[user][id][$eq]=${userId}&pagination[limit]=5`;
    return this.http.get<{ data: any[] }>(url).pipe(
      map((response) => response.data),
      catchError((error) => {
        this.toastr.error(this.translate.instant('FAVOURITES_LOADING_ERROR'));
        return throwError(() => new Error('Erreur lors du chargement des favoris'));
      })
    );
  }
}
