import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { API_CONFIG } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  createAnalytics(): any {
    const url = `${API_CONFIG.endpoint}/analytics`;
    return this.http
      .post(url, { data: { } })
      .subscribe({
        next: () => {},
        error: () => {
          console.log('Error creating analytics entry');
        },
      });
  }
}
