import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import {TranslateService} from "@ngx-translate/core";
import { AnalyticsService } from '@app/services/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  isLoading: boolean = false;
  showPassword: boolean = false;
  currentLang: string = 'en';

  constructor(
      private formBuilder: FormBuilder,
      private authService: AuthService,
      private translate: TranslateService,
      private analyticsService: AnalyticsService
    ) {
      this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
      });
      this.currentLang = this.translate.currentLang;
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;
      this.isLoading = true;
      this.authService.login(email, password).subscribe({
        next: (successful) => {
          this.isLoading = false;

          if (!successful) return;

          this.analyticsService.createAnalytics();
        },
        error: () => {
          this.isLoading = false;
        },
      });
    }
  }

  switchPasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  // Translation
  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.currentLang = lang;
  }

  getLanguages(): string[] {
    return this.translate.getLangs();
  }
}
